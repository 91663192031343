/* This hook fires a callback when some element becomes visible using intersection observer */
import { useEffect, useRef } from 'react'
import { createIndividualObserver } from './util'


type UseVisibilityTriggerOptions = {
  skip?: boolean
  intersectionRatio?: number
}

const useVisibilityTrigger = (callback: () => void, options: UseVisibilityTriggerOptions = {}) => {
  const { skip, intersectionRatio = 0.2 } = options

  const callbackRef = useRef(callback)
  callbackRef.current = callback

  const ref = useRef()

  useEffect(() => {

    const node = ref.current

    if (!node || skip) {
      return
    }

    const observer = createIndividualObserver({
      threshold: [ 0, 0.20, 1 ],
    })
    let unobserve = () => {
      observer.unobserve(node)
      // we can't call it twice
      unobserve = () => {}
    }

    const listener = (entry) => {
      // if we're intersecting with equal or more that 20% of the item
      const isTriggered = entry.isIntersecting && entry.intersectionRatio >= intersectionRatio

      if (isTriggered) {
        callbackRef.current()
        unobserve()
      }
    }

    observer.observe(node, listener)

    return () => {
      unobserve()
    }
  }, [ skip ])

  return ref
}


export default useVisibilityTrigger
